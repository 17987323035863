$body-bg: #000000;

$font-family-base: 'Dinot';
$font-family-sans-serif: 'Dinot';
$font-family-monospace: 'Dinot';

$border-radius:               0 !default;
$border-radius-sm:            0 !default;
$border-radius-lg:            0 !default;

$container-padding-x: 1.5rem !default;

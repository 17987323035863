@keyframes topbar-x {
  0% {
    top: 0px;
    transform: rotate(0deg);
  }
  45% {
    top: 6px;
    transform: rotate(145deg);
  }
  75% {
    transform: rotate(130deg);
  }
  100% {
    transform: rotate(135deg);
  }
}
@-webkit-keyframes topbar-x {
  0% {
    top: 0px;
    -webkit-transform: rotate(0deg);
  }
  45% {
    top: 6px;
    -webkit-transform: rotate(145deg);
  }
  75% {
    -webkit-transform: rotate(130deg);
  }
  100% {
    -webkit-transform: rotate(135deg);
  }
}
@-moz-keyframes topbar-x {
  0% {
    top: 0px;
    -moz-transform: rotate(0deg);
  }
  45% {
    top: 6px;
    -moz-transform: rotate(145deg);
  }
  75% {
    -moz-transform: rotate(130deg);
  }
  100% {
    -moz-transform: rotate(135deg);
  }
}
@keyframes topbar-back {
  0% {
    top: 6px;
    transform: rotate(135deg);
  }
  45% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    top: 0px;
    transform: rotate(0);
  }
}
@-webkit-keyframes topbar-back {
  0% {
    top: 6px;
    -webkit-transform: rotate(135deg);
  }
  45% {
    -webkit-transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
  }
  100% {
    top: 0px;
    -webkit-transform: rotate(0);
  }
}
@-moz-keyframes topbar-back {
  0% {
    top: 6px;
    -moz-transform: rotate(135deg);
  }
  45% {
    -moz-transform: rotate(-10deg);
  }
  75% {
    -moz-transform: rotate(5deg);
  }
  100% {
    top: 0px;
    -moz-transform: rotate(0);
  }
}
@keyframes bottombar-x {
  0% {
    bottom: 0px;
    transform: rotate(0deg);
  }
  45% {
    bottom: 6px;
    transform: rotate(-145deg);
  }
  75% {
    transform: rotate(-130deg);
  }
  100% {
    transform: rotate(-135deg);
  }
}
@-webkit-keyframes bottombar-x {
  0% {
    bottom: 0px;
    -webkit-transform: rotate(0deg);
  }
  45% {
    bottom: 6px;
    -webkit-transform: rotate(-145deg);
  }
  75% {
    -webkit-transform: rotate(-130deg);
  }
  100% {
    -webkit-transform: rotate(-135deg);
  }
}
@-moz-keyframes bottombar-x {
  0% {
    bottom: 0px;
    -moz-transform: rotate(0deg);
  }
  45% {
    bottom: 6px;
    -moz-transform: rotate(-145deg);
  }
  75% {
    -moz-transform: rotate(-130deg);
  }
  100% {
    -moz-transform: rotate(-135deg);
  }
}
@keyframes bottombar-back {
  0% {
    bottom: 6px;
    transform: rotate(-135deg);
  }
  45% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    bottom: 0px;
    transform: rotate(0);
  }
}
@-webkit-keyframes bottombar-back {
  0% {
    bottom: 6px;
    -webkit-transform: rotate(-135deg);
  }
  45% {
    -webkit-transform: rotate(10deg);
  }
  75% {
    -webkit-transform: rotate(-5deg);
  }
  100% {
    bottom: 0px;
    -webkit-transform: rotate(0);
  }
}
@-moz-keyframes bottombar-back {
  0% {
    bottom: 6px;
    -moz-transform: rotate(-135deg);
  }
  45% {
    -moz-transform: rotate(10deg);
  }
  75% {
    -moz-transform: rotate(-5deg);
  }
  100% {
    bottom: 0px;
    -moz-transform: rotate(0);
  }
}

@font-face {
  font-family: 'Dinot';
  font-style: italic;
  font-weight: 300;
  src: url("/fonts/dinot/DINOT-LightItalic.otf") format("opentype");
}

@font-face {
  font-family: 'Dinot';
  font-weight: 300;
  src: url("/fonts/dinot/DINOT-Light.otf") format("opentype");
}

@font-face {
  font-family: 'Dinot';
  font-style: italic;
  font-weight: 400;
  src: url("/fonts/dinot/DINOT-Italic.otf") format("opentype");
}

@font-face {
  font-family: 'Dinot';
  font-weight: 400;
  src: url("/fonts/dinot/DINOT.otf") format("opentype");
}

@font-face {
  font-family: 'Dinot';
  font-style: italic;
  font-weight: 500;
  src: url("/fonts/dinot/DINOT-MediumItalic.otf") format("opentype");
}

@font-face {
  font-family: 'Dinot';
  font-weight: 500;
  src: url("/fonts/dinot/DINOT-Medium.otf") format("opentype");
}

@font-face {
  font-family: 'Dinot';
  font-style: italic;
  font-weight: 700;
  src: url("/fonts/dinot/DINOT-BoldItalic.otf") format("opentype");
}

@font-face {
  font-family: 'Dinot';
  font-weight: 700;
  src: url("/fonts/dinot/DINOT-Bold.otf") format("opentype");
}

@font-face {
  font-family: 'Dinot';
  font-style: italic;
  font-weight: 900;
  src: url("/fonts/dinot/DINOT-BlackItalic.otf") format("opentype");
}

@font-face {
  font-family: 'Dinot';
  font-weight: 900;
  src: url("/fonts/dinot/DINOT-Black.otf") format("opentype");
}
